/**
Markdown
 */
.markdown {

  @include media-breakpoint-up(md) {
    font-size: 1rem;
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child,
  > :last-child .highlight {
    margin-bottom: 0;
  }

  > {
    h1, h2, h3, h4, h5, h6 {
      margin-top: 2rem;
    }
  }

  >h2 {
    margin-bottom: 1rem;

    &:before {
      content: '';
      padding-top: 1em;
      margin-top: -1em;
      display: block;
    }
  }

  >hr {
    @include media-breakpoint-up(md) {
      margin-top: 3em;
      margin-bottom: 3em;
    }
  }

  > table {
    @extend .table, .table-bordered;
  }
}